<template>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th v-for="(header, index) in currentHeaders" :key="index">
          <abbr
            v-if="(header === 'PARTIDO' || header === 'APUESTA') && isPremium"
            @click="onClickInformacion"
            class="games-th"
          >
            <BIcon class="table-info-icon" icon="information-outline" :size="$mq === 'mobile' ? 'is-small' : ''" />
            <abbr>{{ header }}</abbr>
          </abbr>
          <abbr v-else>{{ header }}</abbr>
          <div class="divider"></div>
        </th>
      </tr>
    </thead>

    <tbody>
      <template v-for="(row, index) in currentTeams">
        <tr :key="'desktop-row-' + index">
          <td v-if="$mq !== 'mobile' && false">
            <img
              :src="
                row.teamImg ? `https://az755631.vo.msecnd.net/teams-80/${row.teamImg}` : '/assets/icons/icn-heart.svg'
              "
              alt="Icon"
            />
          </td>
          <td v-if="$mq === 'mobile' && isPremium" class="games-td-premium__parent">
            <div class="games-td-premium">
              <div class="games-td-premium__suggestion">
                <div class="games-td-premium__suggestion-card">
                  <div class="games-td-premium__suggestion-logo">
                    <img
                      v-if="row.logo1"
                      :src="`https://az755631.vo.msecnd.net/teams-80/${row.logo1}`"
                      alt="previous"
                    />
                  </div>
                  <div class="games-td-premium__suggestion-suggestion">{{ row.bet_suggested.suggestion }}</div>
                  <div class="games-td-premium__suggestion-logo">
                    <img
                      v-if="row.logo2"
                      :src="`https://az755631.vo.msecnd.net/teams-80/${row.logo2}`"
                      alt="previous"
                    />
                  </div>
                </div>
              </div>
              <div
                class="games-td-premium__match"
                @click="goToStatistics(row.matchId, row.has_statistics, row.season_id)"
              >
                <div class="games-td-premium__match-card">
                  <div class="games-td-premium__match-logo">
                    <img
                      :src="
                        row.home_team.logo
                          ? `https://az755631.vo.msecnd.net/teams-80/${row.home_team.logo}`
                          : '/assets/icons/icn-heart.svg'
                      "
                      alt="previous"
                    />
                  </div>
                  <div class="games-td-premium__match-vs">VS</div>
                  <div class="games-td-premium__match-logo">
                    <img
                      :src="
                        row.away_team.logo
                          ? `https://az755631.vo.msecnd.net/teams-80/${row.away_team.logo}`
                          : '/assets/icons/icn-heart.svg'
                      "
                      alt="previous"
                    />
                  </div>
                  <div class="games-td-premium__match-tournament">{{ row.tournament_name }}</div>
                </div>
              </div>
            </div>
          </td>
          <td v-if="$mq !== 'mobile'" class="games-td">
            <div
              class="games-box light-card"
              :class="clickToStatistics"
              @click="goToStatistics(row.matchId, row.has_statistics, row.season_id)"
            >
              <div class="columns is-gapless is-mobile is-vcentered game-desktop">
                <div class="column is-5">
                  <div class="columns is-gapless is-mobile is-vcentered">
                    <div class="column is-5 is-2-tablet img team-logo-container">
                      <img
                        :src="
                          row.home_team.logo
                            ? `https://az755631.vo.msecnd.net/teams-80/${row.home_team.logo}`
                            : '/assets/icons/icn-heart.svg'
                        "
                        alt="previous"
                      />
                    </div>
                    <div class="column is-7 is-7-tablet name">{{ row.home_team.acronym }}</div>
                  </div>
                </div>
                <div class="column is-2">
                  <div class="columns is-gapless is-mobile is-vcentered">
                    <div class="column is-12 is-2-tablet is-1-mobile games-td-vs">VS</div>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="columns is-gapless is-mobile is-vcentered">
                    <div class="column is-7 is-9-tablet name">{{ row.away_team.acronym }}</div>
                    <div class="column is-5 is-2-tablet img team-logo-container">
                      <img
                        :src="
                          row.away_team.logo
                            ? `https://az755631.vo.msecnd.net/teams-80/${row.away_team.logo}`
                            : '/assets/icons/icn-heart.svg'
                        "
                        alt="previous"
                      />
                    </div>
                  </div>
                </div>
                <div class="tournament">
                  {{ row.tournament_name }}
                </div>
              </div>
            </div>
          </td>
          <td v-else-if="!isPremium" class="games-td-mobile">
            <div
              class="games-td-mobile__card"
              :class="clickToStatistics"
              @click="goToStatistics(row.matchId, row.has_statistics, row.season_id)"
            >
              <div class="games-td-mobile__logo">
                <img
                  :src="
                    row.home_team.logo
                      ? `https://az755631.vo.msecnd.net/teams-80/${row.home_team.logo}`
                      : '/assets/icons/icn-heart.svg'
                  "
                  alt="previous"
                />
              </div>
              <div class="games-td-mobile__vs">VS</div>
              <div class="games-td-mobile__logo">
                <img
                  :src="
                    row.away_team.logo
                      ? `https://az755631.vo.msecnd.net/teams-80/${row.away_team.logo}`
                      : '/assets/icons/icn-heart.svg'
                  "
                  alt="previous"
                />
              </div>
              <div class="games-td-mobile__match-tournament">{{ row.tournament_name }}</div>
            </div>
          </td>
          <td v-if="$mq !== 'mobile'" class="suggested-td">
            <div
              class="suggested-box light-card"
              :class="{ clickToStatistics }"
              @click="goToStatistics(row.matchId, row.has_statistics, row.season_id)"
            >
              <div
                class="columns is-gapless is-mobile is-vcentered"
                :class="{ 'is-centered': $mq === 'mobile' || $mq === 'tablet' }"
              >
                <div class="column is-3 is-2-tablet img team-logo-container">
                  <img v-if="row.logo1" :src="`https://az755631.vo.msecnd.net/teams-80/${row.logo1}`" alt="previous" />
                </div>
                <div class="column is-5 is-7-tablet is-7-mobile suggestion-text">
                  {{ row.bet_suggested.suggestion }}
                </div>
                <div class="column is-3 is-2-tablet img team-logo-container">
                  <img v-if="row.logo2" :src="`https://az755631.vo.msecnd.net/teams-80/${row.logo2}`" alt="previous" />
                </div>
              </div>
            </div>
          </td>
          <td v-else-if="!isPremium" class="suggested-td-mobile">
            <div class="suggested-td-mobile__card bordered">
              <div class="suggested-td-mobile__logo">
                <img v-if="row.logo1" :src="`https://az755631.vo.msecnd.net/teams-80/${row.logo1}`" alt="previous" />
              </div>
              <div class="suggested-td-mobile__suggestion">{{ row.bet_suggested.suggestion }}</div>
              <div class="suggested-td-mobile__logo">
                <img v-if="row.logo2" :src="`https://az755631.vo.msecnd.net/teams-80/${row.logo2}`" alt="previous" />
              </div>
            </div>
          </td>
          <td class="winrate-td">{{ row.winrate }}</td>
          <td v-if="isPremium" class="investment-td">
            <div class="investment-box">
              <div class="investment">
                <input
                  title=""
                  :ref="`investment-${row.game_id}`"
                  @click.prevent="verifyPremium($event.target.value, row.game_id)"
                  :value="row.investment"
                  type="number"
                  :min="1"
                  class="custom-input"
                  @input="onUpdateInvestment($event.target.value, row.game_id)"
                  @change="onChangeInvestment($event.target.value, row.game_id)"
                  @keydown="checkIfEmpty($event, row.game_id)"
                  @keyup="checkIfEmpty2($event, row.game_id)"
                />
              </div>
            </div>
          </td>
          <td v-if="$mq !== 'mobile' || ($mq === 'mobile' && !qps)" class="statistic-td">
            <div class="stadistic-icon-container" v-if="row.has_statistics === 1">
              <div class="stadistic-icon-box">
                <span @click="toMatchAnalysis(row.matchId, row.season_id)">
                  <img src="/assets/icons/icn_info.svg" alt="Icon" />
                </span>
              </div>
            </div>
          </td>
          <td v-if="qps" class="win-lose-td">
            <div :class="`win-lose${$mq === 'mobile' ? '-mobile' : ''}`">
              <span>G</span>
              <BSwitch
                size="is-small"
                :value="row.qps"
                passive-type="is-success"
                type="is-turned-off"
                @input="updateQps({ value: $event, id: row.game_id })"
                >P</BSwitch
              >
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { BIcon } from 'buefy/dist/esm/icon';
import { BSwitch } from 'buefy/dist/esm/switch';
import { clickBtnEstadistica, clickInputTipsInversion, ClickTipsTablaVerInformacion } from '@/utils/analytics';

export default {
  name: 'BetDataTable',
  components: {
    BIcon,
    BSwitch,
  },
  data() {
    return {
      selectedInvestmentValue: null,
    };
  },
  computed: {
    ...mapState('tips', ['qps', 'currentTeams', 'resetButtonVisibility']),
    ...mapState(['displayWidth']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapGetters('tips', ['getUserType']),

    isResetButtonVisible() {
      return this.resetButtonVisibility;
    },

    isPremium() {
      return this.getUserType === 2;
    },

    currentHeaders() {
      let headers = [];
      if (this.$mq === 'mobile') {
        headers = this.isPremium
          ? ['APUESTA', '%', 'MONTO', 'INFO', 'SIMULADOR']
          : ['PARTIDO', 'QUÉ APOSTAR', 'PROB.', 'INFO', 'SIMULADOR'];
      } else {
        headers = this.isPremium
          ? [
              // 'Mis Apuestas',
              'PARTIDO',
              'APUESTA SUGERIDA',
              'PROBABILIDAD',
              'MONTO',
              'INFO',
              'SIMULADOR',
            ]
          : ['PARTIDO', 'APUESTA SUGERIDA', 'PROBABILIDAD', 'INFO', 'SIMULADOR'];
      }
      if (!this.qps) {
        headers.pop();
      } else if (this.$mq === 'mobile' && this.isPremium) {
        headers.splice(3, 1);
      }
      return headers;
    },

    clickToStatistics() {
      return this.$mq === 'mobile' ? 'clickable' : '';
    },

    isOutlined() {
      return { outlined: this.isPremium && !this.isResetButtonVisible };
    },

    decypheredTableData() {
      return this.currentTeams.map(team => {
        return team;
      });
    },
  },
  methods: {
    ...mapActions('tips', ['updateInvestment', 'updateQps']),
    ...mapMutations('tips', ['toggleLoading']),
    ...mapMutations(['SET_PREMIUM_MESSAGE', 'setModalInfoVisible']),
    ...mapActions('routes', ['goToGame']),
    ...mapMutations(['setIsSectionLoading']),
    ...mapMutations('general', ['setPreviewSectionAnalytics', 'setPreviewSection']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapActions('alertGeneral', ['setAlert']),
    goToStatistics(id, hasStatistics, currentSeasonId) {
      if (this.$mq === 'mobile' && hasStatistics === 1) this.toMatchAnalysis(id, currentSeasonId);
    },
    toMatchAnalysis(gameId, currentSeasonId) {
      this.setPreviewSectionAnalytics('Tips');
      clickBtnEstadistica(gameId, this.getPremiumAccount, this.getIsGolstats);
      this.goToGame({
        gameId,
        currentSeasonId,
      });
    },
    verifyPremium(investment, game_id) {
      this.selectedInvestmentValue = investment;
      if (!this.getPremiumAccount) {
        clickInputTipsInversion(investment, this.getPremiumAccount, game_id, this.getIsGolstats);
        this.SET_PREMIUM_MESSAGE(true);
      } else {
        this.$refs[`investment-${game_id}`][0].select();
      }
    },
    onChangeInvestment(investment, game_id) {
      clickInputTipsInversion(investment, this.getPremiumAccount, game_id, this.getIsGolstats);
    },
    onUpdateInvestment(newValue, game_id) {
      if (this.$refs[`investment-${game_id}`][0].validity.valid) {
        this.updateInvestment({ amount: newValue, id: game_id });
        this.$refs[`investment-${game_id}`][0].value = newValue;
        this.selectedInvestmentValue = newValue;
      } else {
        let auxVal;
        if (!this.$refs[`investment-${game_id}`][0].value) {
          auxVal = 1;
        } else {
          auxVal = this.selectedInvestmentValue;
        }
        this.$refs[`investment-${game_id}`][0].value = auxVal;
        this.updateInvestment({ amount: auxVal, id: game_id });
      }
      this.toggleLoading();
    },
    checkIfEmpty(event, game_id) {
      if (this.$refs[`investment-${game_id}`][0].value == 1 && event.key === 'Backspace') {
        event.preventDefault();
      }
    },
    checkIfEmpty2(event, game_id) {
      if (this.$refs[`investment-${game_id}`][0].value == '') {
        this.$refs[`investment-${game_id}`][0].value = 1;
      }
    },
    onClickInformacion() {
      ClickTipsTablaVerInformacion(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({
        isVisible: true,
        title: 'INFORMACIÓN',
        componentName: 'TableInfo',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.games-th {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  & .table-info-icon {
    position: absolute;
    left: 1.2vw;

    & i .mdi-24px.mdi-set,
    .mdi-24px.mdi:before {
      font-size: 550px !important;
    }
  }
}
.games-td {
  width: 280px;

  font-size: medium;

  &-vs {
    text-align: center;
    font-size: small;
  }
}

.games-td-mobile,
.suggested-td-mobile {
  &__card {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 80px;
    width: 30vw;
    padding: 0.5vh 0;
    justify-content: space-around;
    margin: 0 auto;
    &.bordered {
      border: solid 1px #dadada;
      border-radius: 3px;
    }

    & > .games-td-mobile__match-tournament {
      position: absolute;
      left: 0;
      bottom: 4px;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 0.6rem;
      line-height: 0.6rem;
      font-family: Circular-Std-Medium, sans-serif;
    }
  }

  &__vs,
  &__suggestion {
    padding: 0 1vw;
  }

  &__vs {
    font-size: 80%;
  }

  &__suggestion {
    font-size: 120%;
  }

  &__logo {
    height: auto;
    width: 90%;
    max-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      padding: 2%;
      height: inherit;
    }
  }
}

.games-td-premium {
  width: 27vw;
  border: solid 1px #dadada;
  border-radius: 3px;
  margin: 5px auto;

  &__parent {
    width: 28vw;
    min-width: 100px;
  }

  &__suggestion,
  &__suggestion-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__suggestion {
    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11vw;
      max-width: 52px;
      padding: 1%;
    }

    &-suggestion {
      font-size: 3vw;
    }
  }

  &__match {
    padding: 2px 0;

    &-card {
      display: flex;
      align-items: center;
      width: 100%;

      & > div {
        padding: 0 2px;
      }
    }

    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6vw;
      max-width: 33px;
    }

    &-vs {
      font-size: 2vw;
    }

    &-tournament {
      font-size: 2vw;
      line-height: 0.5rem;
      font-family: Circular-Std-Medium, sans-serif;
    }
  }
}

.winrate-td {
  width: 13vw;
  max-width: 120px !important;
  min-width: 60px;
  font-size: 1.1rem;
}
.investment-td {
  width: 11vw;
  max-width: 100px !important;
  min-width: 60px;
  font-size: 1.1rem;
}

.statistic-td {
  width: 12vw;
  min-width: 50px;
}

.statistics-header-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  abbr {
    margin-right: 10px;
  }
}

.games-box-mobile {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px 35px;
  height: 10px;
  font-size: 0.65rem;
  min-height: 0;
  .columns {
    padding: 0px;
    width: 100%;
  }
  .games-td-vs {
    font-size: 0.55rem;
    font-weight: 400;
  }
  .img img {
    width: auto;
  }
  .name {
    line-height: initial;
  }
  * {
    min-height: 0;
  }
}

.column {
  text-align: center;
  margin: auto;
}

.stadistic-icon-container {
  display: flex;
  justify-content: center;
  width: 10vw;
  max-width: 120px;
  min-width: 40px;
  margin: 0 auto;
  .stadistic-icon-box {
    background-color: #3c3c3c;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px rgba(0, 0, 0, 0.22);
    cursor: pointer;
  }
  .stadistic-icon-box:hover {
    background-color: #77a405;
  }
}
.suggested-box.light-card {
  display: flex;
  border: solid 1px #dadada;
  justify-content: center;
  border-radius: 8px;
  height: 50px;
  min-width: 150px;
  max-width: 260px;
  width: 100%;
  margin: 0 auto;
  .suggestion-text {
    margin: auto;
  }
  .columns {
    width: 100%;
  }
  .name {
    font-size: 15px;
  }
  .amount {
    font-size: 20px;
  }
}
.investment-box {
  display: flex;
  justify-content: center;
  align-self: center;
  .investment {
    border: solid 1px #dadada;
    width: fit-content;
    border-radius: 4px;
    min-width: 50px;
    padding: 5px 0px;
    .custom-input {
      border: none;
      padding: 0px;
      font-family: 'Circular-Std-Bold';
      color: #494a4b;
      font-size: 1rem;
      text-align: center;
      width: 60px;
      background-image: url('/assets/icons/icn-pesos.svg');
      background-position: 1px;
      background-repeat: no-repeat;
      background-size: 15px;
      padding-left: 15px;
      &:focus {
        outline: none;
      }
      &:not(output):-moz-ui-invalid {
        box-shadow: none !important;
      }
    }
  }
}
.table {
  font-family: 'Circular-Std-Bold';
  color: #494a4b;
  font-size: 17px;

  input {
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  th {
    background-color: #12063c;
    color: #ffffff;
    position: relative;
    text-transform: uppercase;
    text-align: center !important;
    font-family: 'Circular-Std-Bold';
    font-size: 12px;
    padding: 10px 0px;
    vertical-align: middle;

    & > abbr:hover {
      cursor: pointer;
    }
  }
  td {
    text-align: center !important;
    line-height: 1;
    height: 60px;
    vertical-align: middle;
  }
  .divider {
    position: absolute;
    right: 0px;
    top: 35%;
    width: 1px;
    height: 30%;
    background-color: #fff;
  }
  td:first-child {
    text-align: left !important;
    line-height: 3;
    padding-left: 15px;
  }

  th:last-child {
    .divider {
      display: none;
    }
  }
  .team-logo-container {
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: transparent;
    & > img {
      max-height: 100%;
      padding-bottom: 4px;
    }
  }
  .win-lose-td {
    width: 10vw;
  }
  .win-lose,
  .win-lose-mobile {
    display: flex;
    font-size: 0.75rem;
    justify-content: center;
    align-items: center;
    span {
      padding-right: 4px;
    }
  }
  .win-lose {
    min-width: 85px;
  }
  .win-lose-mobile {
    min-width: 40px;
  }
}

.games-box {
  & > .game-desktop {
    position: relative;

    & > .tournament {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 0.6rem;
      line-height: 0.6rem;
      font-family: Circular-Std-Medium, sans-serif;
    }
  }
}

@media screen and (max-width: 976px) {
  .statistics-header-icon {
    abbr {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 850px) {
  .statistics-header-icon {
    abbr {
      margin-right: 2px;
    }
  }
}
@media screen and (max-width: 768px) {
  table {
    td {
      padding: 0.1em !important;
    }
  }
  .clickable {
    cursor: pointer;
  }
}
@media screen and (max-width: 460px) {
  .table th {
    font-size: 3.2vw;
  }

  .table {
    td {
      font-size: 14px;
    }

    .team-logo-container {
      & > img {
        padding-bottom: 0;
        max-width: 80%;
      }
    }
  }
  .winrate-td {
    min-width: 40px;
  }
}
@media screen and (max-width: 360px) {
  .table {
    td {
      font-size: 12px;
    }
  }
}

.outlined {
  border-color: #428ee6 !important;
  border-width: 3px !important;
}
</style>
